.bottom-message {
  position: absolute;
  left: 0;
  bottom: 10px;
  font-size: 8px;
}

.background-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 56px);
  background-image: url("/public/background.jpeg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin-right: 15px;
}