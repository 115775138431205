svg.icon-cursor-pointer {
  cursor: pointer;
}

div.form-group-relative {
  position: relative;
}

div.add-buttons {
  position: absolute;
  right: -35px;
  top: 32px;
}
